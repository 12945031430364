import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CFormGroup,
  CLabel,
  CSelect,
  CTooltip,
} from '@coreui/react';
import React from 'react';
import UndoSelection from '../UndoSelection';
import styles from './ModuleSelection.module.scss';
import ImageWithModal from '../ImageWithModal';
import IIcon from '../../assets/icons/i.svg';
import { useModuleSelectionItem } from './hooks/useModuleSelectionItem';
import { maxCapacityGroup, ModuleSelectionProps, scaleIntervalGroup } from './types';
import { sortMaxCapcityGroups, sortScaleDivisionGroups } from './hooks/utils';
import { useTranslation } from 'react-i18next';

const ModuleSelection: React.FC<ModuleSelectionProps> = ({
  category,
  active,
  selectedModule,
  onModuleSelection,
  onUndoModuleSelection,
  onDropdownChange,
  selectedDropdownValues,
}) => {
  const {
    compMaxCapacityGroups,
    scaleInterval,
    handleMaxCapacitySelection,
    maxCapacity,
    handleScaleIntervalSelection,
    compScaleIntervalGroups,
    filteredModules,
    handleModuleSelection,
  } = useModuleSelectionItem({
    category,
    selectedModule,
    onDropdownChange,
    selectedDropdownValues,
    onModuleSelection,
    onUndoModuleSelection,
  });

  const { t } = useTranslation('common');

  return (
    <CCard data-testid={`${category.id}-card`} className={active ? `${styles.active} ${styles.card}` : styles.card}>
      {category.newProduct && (
        <div className={styles.newProductContainer}>
          <div className={styles.newProductEmpty}></div>
          <div className={styles.newProductTag}>
            <p>{t('helpText.step1.newProduct')}</p>
          </div>
        </div>
      )}
      <CCardHeader className={category.newProduct ? 'pt-0 mt-2' : 'mt-2'}>
        <>
          {category.name}{' '}
          <CTooltip content={`<div class="text-left">${category.info}</div>`}>
            <img src={IIcon} width={20} height={20} alt="Info" />
          </CTooltip>
        </>
      </CCardHeader>
      <CCardBody>
        <ImageWithModal
          data-testid={`${category.id}-image`}
          src={category.image}
          srcFullsize={category.image}
          fluidGrow
        />
        <CFormGroup>
          <CLabel data-testid={`${category.id}-maxcap-label`} htmlFor={`maxCapacity-${category.id}`} className="mt-1">
            {t('moduleSelection.maximumCapacity')}*
          </CLabel>
          <CSelect
            data-testid={`${category.id}-maxcap-select`}
            id={`maxCapacity-${category.id}`}
            onChange={handleMaxCapacitySelection}
            value={maxCapacity ? maxCapacity.name : ''}
          >
            <option value="">{t('general.select')}</option>
            {compMaxCapacityGroups
              .sort((a: maxCapacityGroup, b: maxCapacityGroup) => sortMaxCapcityGroups(a, b))
              .map((cap: maxCapacityGroup) => (
                <option value={cap.name} key={cap.name}>
                  {cap.name} g
                </option>
              ))}
          </CSelect>
        </CFormGroup>
        <CFormGroup>
          <CLabel data-testid={`${category.id}-interval-label`} htmlFor={`scaleInterval-${category.id}`}>
            {t('moduleSelection.scaleInterval')}*
          </CLabel>
          <CSelect
            data-testid={`${category.id}-interval-select`}
            id={`scaleInterval-${category.id}`}
            onChange={handleScaleIntervalSelection}
            value={scaleInterval ? scaleInterval.name : ''}
          >
            <option value="">{t('general.select')}</option>
            {compScaleIntervalGroups
              .sort((a: scaleIntervalGroup, b: scaleIntervalGroup) => sortScaleDivisionGroups(a, b))
              .map((scaleIntervalGroup: scaleIntervalGroup) => (
                <option value={scaleIntervalGroup.name} key={scaleIntervalGroup.name}>
                  {scaleIntervalGroup.name} mg
                </option>
              ))}
          </CSelect>
        </CFormGroup>
        {/* helper for debbuging */}
        {/* <ul>
          {filteredModules.map((weightingModule) => (
            <li key={`module-${weightingModule.name}`}>{weightingModule.name}</li>
          ))}
        </ul> */}
      </CCardBody>
      <CCardFooter>
        {selectedModule ? (
          <UndoSelection onUndoSelection={onUndoModuleSelection} />
        ) : (
          <CButton
            data-testid={`${category.id}-select-button`}
            id={`select-${category.id}`}
            className={styles.btnSelect}
            color="ghost"
            block
            disabled={!(filteredModules.length === 1 && scaleInterval && maxCapacity)}
            onClick={() => {
              handleModuleSelection(filteredModules[0]);
            }}
          >
            {t('general.select')}
          </CButton>
        )}
      </CCardFooter>
    </CCard>
  );
};

export default ModuleSelection;
