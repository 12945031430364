import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CLabel,
  CRow,
  CSelect,
  CTextarea,
} from '@coreui/react';
import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import countries from '../../data/countries';
import { useRequestForm } from './hooks/useRequestForm';
import { FormDTO } from './types';
import styles from './RequestQuote.module.scss';
import { industries, vwrIndustries, vwrInterests } from '../../data/industries/industry';
import { Configuration, Industry, Product } from '../../data/types';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { configurationState } from '../../states';
import PlatformContext from '../../context/PlatformContext';
import { QuoteRequestDTOContextEnum } from '../../context/QuoteRequestDTOContextEnum';
import { overrideMaterialNumber } from '../../data/products/utils';
import { countryStateCodes } from '../../data/countries/states';

interface RequestFormProps {
  recommendedConfiguration?: Configuration;
  recommendedProduct?: Product;
}

const RequestForm: React.FC<RequestFormProps> = ({ recommendedConfiguration, recommendedProduct }): JSX.Element => {
  const platform = React.useContext(PlatformContext);
  const isSartorius = QuoteRequestDTOContextEnum.Sartorius === platform;
  const isThermoFisher = QuoteRequestDTOContextEnum.Tf === platform;
  const isSartoriusOrThermoFisher = isSartorius || isThermoFisher;
  const { t } = useTranslation('common');
  const [config, setConfig] = useRecoilState(configurationState);
  const [currentIndustry, setCurrentIndustry] = useState<Industry | undefined>();
  const [submitError, setSubmitError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<FormDTO>({ mode: 'onChange' });
  const { formElement, onSubmit, product, status } = useRequestForm(
    setSubmitError,
    recommendedConfiguration,
    recommendedProduct,
  );

  const onCountrySelectChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSelectedCountry(e.target.value);
  };

  const filteredStates = useMemo(() => {
    if (!selectedCountry && !isSartorius) return [];
    return countryStateCodes[selectedCountry] || [];
  }, [selectedCountry, isSartorius]);

  function errorLabel(msg: string) {
    return (
      <span data-testid="error-label" className="text-danger font-xl">
        {msg}
      </span>
    );
  }

  const industriesFlatten = Array.from(industries, ([key, value]) => {
    return { key: key, id: value.id };
  });

  const vwrIndustriesFlatten = Array.from(vwrIndustries, ([key, value]) => ({ key, value }));

  const vwrInterestsFlatten = Array.from(vwrInterests, ([key, value]) => ({ key, value }));

  const handleIndustrySelection: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCurrentIndustry(industries.get(e.target.value));
  };

  const handlePromoCode: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setConfig({
      ...config,
      promoCode: e.target.value,
    });
  };

  return (
    <>
      <CForm
        onSubmit={handleSubmit(onSubmit)}
        noValidate={true}
        innerRef={formElement}
        className="request-quote-form"
        data-testid="overall-form"
      >
        <CFormGroup className="bg-light px-3 py-4">
          <h4 className="mb-4">
            <strong>{t(`requestQuote.form.${status}`)}</strong>
          </h4>
          {status === 'complete' && product && !recommendedProduct && (
            <>
              <div data-testid="product-number" className="text-muted font-2xl">
                {t(`requestQuote.form.productNumber`)}
              </div>
              <div>{overrideMaterialNumber(product.materialNumber)}</div>
            </>
          )}
          <CRow className="mt-4">
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="firstName">{t(`requestQuote.form.firstName`)}*</CLabel>
                <CInput
                  invalid={errors.firstName !== undefined}
                  data-testid="firstname-input"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder={t(`requestQuote.form.firstName`) || ''}
                  required
                  innerRef={register({
                    required: {
                      value: true,
                      message: t(`error.form.firstName`),
                    },
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                />
                {errors.firstName?.message && errorLabel(errors.firstName.message)}
              </CFormGroup>
            </CCol>
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="lastName">{t(`requestQuote.form.lastName`)}*</CLabel>
                <CInput
                  invalid={errors.lastName !== undefined}
                  data-testid="lastname-input"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder={t(`requestQuote.form.lastName`) || ''}
                  required
                  innerRef={register({
                    required: {
                      value: true,
                      message: t(`error.form.lastName`),
                    },
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                />
                {errors.lastName?.message && errorLabel(errors.lastName.message)}
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="email">{t(`requestQuote.form.email`)}*</CLabel>
                <CInput
                  invalid={errors.email !== undefined}
                  data-testid="email-input"
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t(`requestQuote.form.email`) || ''}
                  required
                  innerRef={register({
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t(`error.form.invalidEmail`),
                    },
                  })}
                />
                {errors.email && errorLabel(errors.email.message || t(`error.form.email`))}
              </CFormGroup>
            </CCol>
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="phone">
                  {t(`requestQuote.form.phone`)}
                  {isSartoriusOrThermoFisher && '*'}
                </CLabel>
                <CInput
                  invalid={errors.phone !== undefined}
                  data-testid="phone-input"
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder={t(`requestQuote.form.phone`) || ''}
                  required
                  innerRef={register({
                    required: {
                      value: isSartoriusOrThermoFisher,
                      message: t(`error.form.phone`),
                    },
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                />
                {errors.phone?.message && errorLabel(errors.phone.message)}
              </CFormGroup>
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="company">{t(`requestQuote.form.company`)}*</CLabel>
                <CInput
                  invalid={errors.company !== undefined}
                  data-testid="company-input"
                  type="text"
                  id="company"
                  name="company"
                  placeholder={t(`requestQuote.form.company`) || ''}
                  required
                  innerRef={register({
                    required: {
                      value: true,
                      message: t(`error.form.company`),
                    },
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                />
                {errors.company?.message && errorLabel(errors.company.message)}
              </CFormGroup>
            </CCol>
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="country">{t(`requestQuote.form.country`)}*</CLabel>
                <CSelect
                  invalid={errors.country !== undefined}
                  data-testid="country-select"
                  id="country"
                  name="country"
                  required
                  innerRef={register({
                    required: {
                      value: true,
                      message: t(`error.form.country`),
                    },
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                  onChange={onCountrySelectChange}
                >
                  <option value="">{t('general.select')}</option>
                  {countries.map((value) => (
                    <option value={value} key={value}>
                      {t(`country.${value}`)}
                    </option>
                  ))}
                </CSelect>
                {errors.country?.message && errorLabel(errors.country.message)}
              </CFormGroup>
            </CCol>
          </CRow>
          {filteredStates && filteredStates.length > 0 && (
            <CRow className={`mt-2`}>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="state">{t(`requestQuote.form.state`)}*</CLabel>
                  <CSelect
                    invalid={errors.state !== undefined}
                    data-testid="state-select"
                    id="state"
                    name="state"
                    required
                    innerRef={register({
                      required: {
                        value: true,
                        message: t(`error.form.state`),
                      },
                      maxLength: {
                        value: 100,
                        message: t(`error.form.invalidMaxLength100`),
                      },
                    })}
                  >
                    <option value="">{t('general.select')}</option>
                    {filteredStates.map((value) => (
                      <option value={value.code} key={value.code}>
                        {value.name}
                      </option>
                    ))}
                  </CSelect>
                  {errors.state?.message && errorLabel(errors.state.message)}
                </CFormGroup>
              </CCol>
            </CRow>
          )}
          {!isThermoFisher && (
            <CRow className="mt-2">
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="industry">{t(`requestQuote.form.industry`)}*</CLabel>
                  <CSelect
                    invalid={errors.industry !== undefined}
                    data-testid="industry-select"
                    id="industry"
                    name="industry"
                    required
                    innerRef={register({
                      required: {
                        value: true,
                        message: t(`error.form.industry`),
                      },
                      maxLength: {
                        value: 100,
                        message: t(`error.form.invalidMaxLength100`),
                      },
                    })}
                    onChange={handleIndustrySelection}
                  >
                    <option value="">{t('general.select')}</option>
                    {isSartoriusOrThermoFisher
                      ? industriesFlatten.map((industry) => (
                          <option value={industry.key} key={industry.key}>
                            {t(`requestQuote.industry.${industry.id}`)}
                          </option>
                        ))
                      : vwrIndustriesFlatten.map((industry) => (
                          <option value={industry.value} key={industry.key}>
                            {t(`requestQuote.vwrIndustry.${industry.key}`)}
                          </option>
                        ))}
                  </CSelect>
                  {errors.industry?.message && errorLabel(errors.industry.message)}
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="industryFocus">
                    {t(isSartoriusOrThermoFisher ? `requestQuote.form.industryFocus` : `requestQuote.form.interest`)}*
                  </CLabel>
                  <CSelect
                    invalid={errors.industryFocus !== undefined}
                    data-testid="industry-focus-select"
                    id="industryFocus"
                    name="industryFocus"
                    required
                    innerRef={register({ required: true })}
                  >
                    <option value="">{t('general.select')}</option>
                    {isSartoriusOrThermoFisher
                      ? currentIndustry?.industryFocus.map((singleIndustryFocus) => (
                          <option
                            data-testid="industry-focus-option"
                            key={singleIndustryFocus.key}
                            value={singleIndustryFocus.key}
                          >
                            {t(`requestQuote.industryFocus.${singleIndustryFocus.id}`)}
                          </option>
                        ))
                      : vwrInterestsFlatten.map((interest) => (
                          <option data-testid="industry-focus-option" key={interest.value} value={interest.value}>
                            {t(`requestQuote.interest.${interest.key}`)}
                          </option>
                        ))}
                  </CSelect>
                  {errors.industryFocus &&
                    (isSartoriusOrThermoFisher
                      ? errorLabel(t(`error.form.industryFocus`))
                      : errorLabel(t(`error.form.interest`)))}
                </CFormGroup>
              </CCol>
            </CRow>
          )}
          <CRow className="mt-2">
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="street">
                  {t(`requestQuote.form.street`)}
                  {isSartoriusOrThermoFisher && '*'}
                </CLabel>
                <CInput
                  invalid={errors.street !== undefined}
                  data-testid="street-input"
                  type="text"
                  id="street"
                  name="street"
                  placeholder={t(`requestQuote.form.street`) || ''}
                  required
                  innerRef={register({
                    required: {
                      value: isSartoriusOrThermoFisher,
                      message: t(`error.form.street`),
                    },
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                />
              </CFormGroup>
              {errors.street?.message && errorLabel(errors.street.message)}
            </CCol>
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="addressNumber">{t(`requestQuote.form.addressNumber`)}</CLabel>
                <CInput
                  data-testid="addressNumber-input"
                  type="text"
                  id="addressNumber"
                  name="addressNumber"
                  placeholder={t(`requestQuote.form.addressNumber`) || ''}
                  innerRef={register({
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                />
              </CFormGroup>
              {errors.addressNumber?.message && errorLabel(errors.addressNumber.message)}
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="zipcode">
                  {t(`requestQuote.form.zipCode`)}
                  {isSartoriusOrThermoFisher && '*'}
                </CLabel>
                <CInput
                  invalid={errors.zipcode !== undefined}
                  data-testid="zipcode-input"
                  type="text"
                  id="zipcode"
                  name="zipcode"
                  placeholder={t(`requestQuote.form.zipCode`) || ''}
                  required
                  innerRef={register({
                    required: {
                      value: isSartoriusOrThermoFisher,
                      message: t(`error.form.zipCode`),
                    },
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                />
              </CFormGroup>
              {errors.zipcode?.message && errorLabel(errors.zipcode.message)}
            </CCol>
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="city">
                  {t(`requestQuote.form.city`)}
                  {isSartoriusOrThermoFisher && '*'}
                </CLabel>
                <CInput
                  invalid={errors.city !== undefined}
                  data-testid="city-input"
                  type="text"
                  id="city"
                  name="city"
                  placeholder={t(`requestQuote.form.city`) || ''}
                  required
                  innerRef={register({
                    required: {
                      value: isSartoriusOrThermoFisher,
                      message: t(`error.form.city`),
                    },
                    maxLength: {
                      value: 100,
                      message: t(`error.form.invalidMaxLength100`),
                    },
                  })}
                />
              </CFormGroup>
              {errors.city?.message && errorLabel(errors.city.message)}
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="timelineOfPurchase">{t(`requestQuote.form.timelineOfPurchase.label`)}</CLabel>
                <CSelect
                  data-testid="timeline-select"
                  id="timelineOfPurchase"
                  name="timelineOfPurchase"
                  innerRef={register}
                >
                  <option value="">{t('general.select')}</option>
                  <option>1 {t(`requestQuote.form.timelineOfPurchase.month`)}</option>
                  <option>2 {t(`requestQuote.form.timelineOfPurchase.months`)}</option>
                  <option>3-6 {t(`requestQuote.form.timelineOfPurchase.months`)}</option>
                  <option>6-12 {t(`requestQuote.form.timelineOfPurchase.months`)}</option>
                  <option>&gt; 12 {t(`requestQuote.form.timelineOfPurchase.months`)}</option>
                  <option>{t(`requestQuote.form.notDecided`)}</option>
                </CSelect>
              </CFormGroup>
            </CCol>
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="units">{t(`requestQuote.form.units.label`)}</CLabel>
                <CSelect data-testid="units-select" id="units" name="units" innerRef={register}>
                  <option value="">{t('general.select')}</option>
                  <option>1 {t(`requestQuote.form.units.unit`)}</option>
                  <option>2 {t(`requestQuote.form.units.units`)}</option>
                  <option>3-5 {t(`requestQuote.form.units.units`)}</option>
                  <option>6-10 {t(`requestQuote.form.units.units`)}</option>
                  <option>&gt; 10 {t(`requestQuote.form.units.units`)}</option>
                  <option>{t(`requestQuote.form.notDecided`)}</option>
                </CSelect>
              </CFormGroup>
            </CCol>
          </CRow>
          {isSartorius && (
            <CRow className="mt-2">
              <CCol>
                <CFormGroup>
                  <CLabel htmlFor="promoCode">{t(`requestQuote.form.promoCode`)}</CLabel>
                  <CInput
                    data-testid="promocode-input"
                    type="text"
                    id="promoCode"
                    name="promoCode"
                    placeholder={t('requestQuote.form.promoCode') || ''}
                    innerRef={register({
                      maxLength: {
                        value: 100,
                        message: t(`error.form.invalidMaxLength100`),
                      },
                    })}
                    onChange={handlePromoCode}
                  />
                </CFormGroup>
                {errors.promoCode?.message && errorLabel(errors.promoCode.message)}
              </CCol>
            </CRow>
          )}
          <CRow className="mt-2">
            <CCol>
              <CFormGroup>
                <CLabel htmlFor="comments">{t(`requestQuote.form.comments`)}</CLabel>
                <CTextarea
                  invalid={errors.comments !== undefined}
                  data-testid="comments-area"
                  type="text"
                  id="comments"
                  name="comments"
                  placeholder={t(`requestQuote.form.questionsOrComments`) || ''}
                  innerRef={register({
                    maxLength: {
                      value: 1000,
                      message: t(`error.form.invalidMaxLength1000`),
                    },
                  })}
                />
              </CFormGroup>
              {errors.comments?.message && errorLabel(errors.comments.message)}
            </CCol>
          </CRow>
          {isSartorius ? (
            <CRow className="mt-2">
              <CCol>
                <CFormGroup variant="custom-checkbox">
                  <CInputCheckbox
                    data-testid="newsletter-checkbox"
                    id="newsletter"
                    innerRef={register}
                    name="newsletter"
                    custom={true}
                  />
                  <CLabel variant="custom-checkbox" className="text-dark" htmlFor="newsletter">
                    <p>
                      <Trans i18nKey="requestQuote.form.newsletter" t={t}>
                        I would like to sign up for newsletters from Sartorius (Sartorius AG and its affiliated
                        companies) based off my personal interests. I can revoke my consent at any time with effect for
                        the future by sending an e-mail to unsubscribe@sartorius.com or by clicking on the "unsubscribe"
                        link in e-mails I have received. Information on the use of your data can be found in our
                        <a
                          href="https://www.sartorius.com/en/data-protection"
                          target="_blank"
                          className={styles.dataPrivacyLink}
                        >
                          privacy policy
                        </a>
                        .
                      </Trans>
                    </p>
                  </CLabel>
                </CFormGroup>
              </CCol>
            </CRow>
          ) : (
            !isThermoFisher && (
              <CRow className="mt-2">
                <CCol>
                  <CFormGroup variant="custom-checkbox">
                    <CLabel>*</CLabel>
                    <CInputCheckbox
                      type="checkbox"
                      data-testid="disclaimer-checkbox"
                      id="disclaimer"
                      name="disclaimer"
                      required={true}
                      innerRef={register({
                        required: {
                          value: true,
                          message: t(`error.form.disclaimer`),
                        },
                      })}
                      custom={true}
                    />
                    <CLabel variant="custom-checkbox" className="text-dark" htmlFor="disclaimer">
                      <p>
                        <Trans i18nKey="requestQuote.form.disclaimer" t={t}>
                          By submitting the form, the data provided will be transferred to VWR International GmbH,
                          Hilpertstraße 20a, 64295 Darmstadt, Germany in connection with your request for a quote and
                          thereafter immediately deleted by Sartorius AG, Otto-Brenner-Str. 20, 37079 Göttingen,
                          Germany.
                        </Trans>
                      </p>
                    </CLabel>
                    {errors.disclaimer?.message && errorLabel(errors.disclaimer.message)}
                  </CFormGroup>
                </CCol>
              </CRow>
            )
          )}
          <CRow>
            <CCol lg="12">{submitError ? errorLabel(t(`error.form.submit`)) : null}</CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol lg="12" className="text-muted font-2xl">
              * {t(`requestQuote.form.mandatory`)}
            </CCol>
          </CRow>
          <CRow className="mt-2">
            <CCol lg="12" className="text-left">
              <CButton data-testid="submit-button" color="primary" type="submit" disabled={!isDirty || isSubmitting}>
                <i className="icon-paper-plane" /> {t(`requestQuote.form.submitRequest`)}
              </CButton>
            </CCol>
          </CRow>
        </CFormGroup>
        <input
          type="text"
          name="surname"
          id="surname"
          ref={register}
          style={{ display: 'none' }}
          tabIndex={-1}
          autoComplete="off"
        />
      </CForm>
    </>
  );
};

export default RequestForm;
