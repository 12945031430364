import { shields } from '../data';
import { DraftShield, WeighingModule } from '../data/types';

export function getAllDraftShields(): ReadonlyArray<DraftShield> {
  return shields;
}

export function getCompatibleDraftShieldNames(module: WeighingModule): DraftShield['name'][] {
  return module.compatibleShields.map(({ name }) => name);
}

export function isCompatibleWithModule(draftShield: DraftShield, module: WeighingModule): boolean {
  const found = module.compatibleShields.find((shield) => shield.name === draftShield.name);
  return !!found;
}
