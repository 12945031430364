import { CButton, CCard, CCardBody, CCardFooter, CCardHeader, CTooltip } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IIcon from '../../assets/icons/i.svg';
import { DisplayModule } from '../../data/types';
import ImageWithModal from '../ImageWithModal';
import UndoSelection from '../UndoSelection';
import SoftwarePackageSelection from './SoftwarePackageSelection';
import HardwarePackageSelection from './HardwarePackageSelection';
import { useRecoilState } from 'recoil';
import { configurationState } from '../../states';

interface UserInterfaceItemProps {
  displayModule: DisplayModule;
  selected: boolean;
  onSelect: () => void;
  onUndoSelection: () => void;
  compatible: boolean;
  isHardwareCompatible?: boolean;
}

const UserInterfaceItem: React.FC<UserInterfaceItemProps> = (props): JSX.Element => {
  const [config, setConfig] = useRecoilState(configurationState);

  const { displayModule, selected, onSelect, onUndoSelection, compatible, isHardwareCompatible = false } = props;
  const backgroundColor = selected ? 'primary' : 'light';
  const canBeSelected = compatible && !selected;
  const { t } = useTranslation('common');

  const [firstload, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!isHardwareCompatible && firstload) {
      setConfig({
        ...config,
        hardwarePackage: undefined,
      });
    } else if (isHardwareCompatible && selected && firstload) {
      setConfig({
        ...config,
        hardwarePackage: {
          name: 'AutomatedDraft',
          label: 'Automated Draft Shield with built in activated Ionizer (HWL)',
          code: 'HWL',
          showOnProductNumber: true,
        },
      });
    }
    setFirstLoad(false);
  }, [config, displayModule.name, isHardwareCompatible, setConfig, firstload, selected]);

  const compatibleStyles = `text-${compatible ? 'dark' : 'muted'} ${compatible ? '' : 'muted'}`;

  const { label: displayModuleLabel } = displayModule;

  return (
    <CCard data-testid={`${displayModule.name}-card`} className={`bg-${backgroundColor} ${compatibleStyles}`}>
      <CCardHeader className={`bg-${backgroundColor} ${compatibleStyles}`}>
        <strong data-testid={`${displayModule.name}-label`} className="mr-1">
          {displayModule.label}
        </strong>
        <CTooltip content={<div className="text-left">{displayModule.description}</div>}>
          <img alt="expand" src={IIcon} width={20} height={20} />
        </CTooltip>
        <div data-testid={`${displayModule.name}-description`} className="text-left pt-5 font-3xl font-weight-normal">
          {displayModule.optionsDescription}
        </div>
        <div
          data-testid={`${displayModule.selectHardwareOptions}-description`}
          className="text-left pt-5 font-3xl font-weight-bold"
        >
          {displayModule.selectHardwareOptions}
        </div>
        <div
          data-testid={`${displayModule.display}-description`}
          className="text-left pt-1 font-3xl font-weight-normal"
        >
          {displayModule.display}
        </div>
      </CCardHeader>
      <CCardBody className="text-center pb-4">
        <ImageWithModal
          data-testid={`${displayModule.name}-image`}
          src={displayModule.image}
          srcFullsize={displayModule.image}
          fluidGrow
        />
      </CCardBody>
      <CCardFooter className={`bg-${backgroundColor} ${compatibleStyles}`}>
        {canBeSelected && (
          <CButton
            data-testid={`${displayModule.name}-button`}
            variant="outline"
            color="dark"
            shape="square"
            block={true}
            className="border border-dark"
            onClick={onSelect}
          >
            {t(`general.select`)}
          </CButton>
        )}
        {selected && <UndoSelection onUndoSelection={onUndoSelection} />}
        {selected &&
          isHardwareCompatible &&
          displayModule.hardwarePackages &&
          displayModule.hardwarePackages.length > 0 && (
            <HardwarePackageSelection disabled={!selected} packages={displayModule.hardwarePackages} />
          )}
        {selected && displayModule.softwarePackages && displayModule.softwarePackages.length > 0 && (
          <SoftwarePackageSelection
            disabled={!selected}
            packages={displayModule.softwarePackages}
            displayModule={displayModule.name}
          />
        )}
        {!compatible && (
          <div className="text-muted" data-testid="not-available">
            {t('displayModule.notAvailable', { displayModuleLabel })}
          </div>
        )}
      </CCardFooter>
    </CCard>
  );
};

export default UserInterfaceItem;
